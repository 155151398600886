.toaster {
	z-index: 6;

	&-content {
		transition: 0.2s;
		opacity: 0;
		visibility: hidden;
		display: flex;
		justify-content: center;
		align-items: center;

		&:not(.custom-toaster-content) {
			background: #FFFFFF;
			box-shadow: 0 0 6px 0 rgba(139,139,139,0.10);
			border-radius: 3px;
			font-size: 12px;
			color: #4A4A4A;
		}
	}

	&.showing-toast-message {
		.toaster-content {
			opacity: 1;
			visibility: visible;
		}
	}
}